<template>
  <div>
    {{ createBy }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        object: {type: Object, required: true}
    },
    computed: {
        ...mapGetters({
            users: 'auth/users'
        }),
        createBy(){
            if(this.object !== null){
                let c = this.users.find(item => item.uid === this.object.createBy)
                if(c) return c.fullname
            }
            return '-'
        }
    }
}
</script>

<style>

</style>